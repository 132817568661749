import React, { useState, useEffect } from "react";
import { Menu } from "../Menu";
import { Header } from "../Header";
import axios from "axios";
import $ from "jquery";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";

export const MarketplacePreview = () => {
    const token = localStorage.getItem("Token");
    const location = useLocation();
    const m_id = location.state && location.state.m_id;
    const [previewPollData, setPreviewPollData] = useState([]);
    const [previewM_Info, setPreviewM_Info] = useState([]);
    const [previewM_Imgs, setPreviewM_Imgs] = useState([]);

    async function viewDescription(marketplaceId) {
        try {
            const formData = new FormData();
            formData.append("m_id", marketplaceId);
            const _previewResponse = await axios.post(
                process.env.REACT_APP_API_KEY + "admin_get_marketplace",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: token,
                    },
                }
            );
            console.log("_previewResponse-------------", _previewResponse);

            if (_previewResponse.data.error_code === 200) {
                setPreviewPollData(_previewResponse.data.data);
                setPreviewM_Info(_previewResponse.data.data.m_info);
                setPreviewM_Imgs(_previewResponse.data.data.image);
                $(".preview_polls").show();
            } else {
                toast.error("Something went wrong.");
            }
        } catch (err) {
            console.log("Login failed", err);
            toast.error("Failed to fetch marketplace data.");
        }
    }

    useEffect(() => {
        if (m_id) {
            viewDescription(m_id);
        }
    }, [m_id]);

    return (
        <>
            <Header />
            <div className="d-flex">
                <Menu />
                <div className="content-wrapper">
                    <div className="border_class2 box_padding">
                        <h1 className="main_heading_h1">Marketplace Item</h1>
                    </div>
                    <div className="card_div mt-2" style={{ width: "100%" }}>
                        <div className="card-header bg-white p-0">
                            <div className="card_inner_div">
                                <div className="row">
                                    <div className="col-md-12 p-0">
                                        <div className="d-flex"
                                            style={{ justifyContent: "space-between" }}
                                        >
                                            <div className="d-flex">
                                                {previewM_Imgs && previewM_Imgs.length > 0 ? (
                                                    previewM_Imgs.map((item, index) => (
                                                        <div key={index} style={{ margin: "2px" }}>
                                                            <img
                                                                className="image_std preview_form_imgs"
                                                                src={item.imgs}
                                                                alt={`Marketplace image ${index + 1}`}
                                                                style={{
                                                                    width: "120px",
                                                                    height: "120px",
                                                                    border: "0.5px solid #D9D9D9",
                                                                    // backgroundColor: "#D9D9D9",
                                                                    borderRadius: "5px",
                                                                    // border:"2px solid red",
                                                                    // border- radius: "5px 0px 0px 0px",
                                                                    objectFit: "cover",
                                                                }}
                                                            />
                                                        </div>
                                                    ))
                                                ) : (
                                                    <img
                                                        src={require("../images/no_image.png")}
                                                        alt="No image available"
                                                        className="preview_form_imgs"
                                                        style={{
                                                            width: "120px",
                                                            height: "120px",
                                                            borderRadius: "8px",
                                                            objectFit: "cover",
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </div>

                                        <div
                                            style={{
                                                display: "flex", // Aligns items horizontally
                                                alignItems: "center", // Centers content vertically
                                                marginTop: "5px",
                                            }}
                                        >
                                            <span style={{ fontSize: "12px", marginRight: "5px", color: "black", fontWeight: "500", marginTop: "5px" }}>
                                                {previewPollData.title}
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex", // Aligns items horizontally
                                                alignItems: "center", // Centers content vertically
                                                marginTop: "5px",
                                            }}
                                        >
                                            <span style={{ fontSize: "12px", marginRight: "5px", color: "black", fontWeight: "500" }}>Price :</span>
                                            <p style={{ margin: 0, fontSize: "14px", fontWeight: "600" }}>₹{previewPollData.price}</p>
                                        </div>
                                        <div>
                                            <hr style={{ marginTop: "10px", marginBottom: "2%", }} />
                                        </div>

                                        <div className="ro" style={{ marginleft: "-5%" }}  >
                                            <div className="">
                                                <div style={{ marginBottom: "10px" }}>
                                                    <label className="all_labels ten_font_class"
                                                        style={{ fontWeight: "600", margin: 0, fontSize: "12px", fontWeight: "700", marginleft: "-15%" }}
                                                    > About Item
                                                    </label>
                                                </div>
                                                <div className="">
                                                    <p>
                                                        {
                                                            <p
                                                                className="desc_class"
                                                                style={{ margin: 0, fontSize: "10px" }}
                                                                dangerouslySetInnerHTML={{
                                                                    __html: previewPollData.description,
                                                                }}
                                                            />
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="">
                                            <hr style={{ marginTop: "15px", marginBottom: "2%", }} />
                                        </div>

                                        <div className="row">
                                            <div className="p-0">
                                                <p className="ten_font_class"
                                                    style={{ color: "#4AA081" }}
                                                >
                                                    Engagement Analytics
                                                </p>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-5 pl-0">
                                                <section style={{ margin: "15px 0px" }}>
                                                    <div className=" table-cards">
                                                        <div className="table-cards">
                                                            <div className="row">
                                                                <div
                                                                    className="col-md-12 p-0"
                                                                    style={{ height: "100%" }}
                                                                >
                                                                    <div
                                                                        className="small-box"
                                                                        style={{
                                                                            height: "70px",
                                                                            padding: "5px",
                                                                            borderRadius: "2.5PX",
                                                                            display: "flex",
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="inner"
                                                                            // onClick={UniDetails}
                                                                            style={{
                                                                                cursor: "pointer",
                                                                                display: "flex",
                                                                                width: "100%",
                                                                                justifyContent: "space-between",
                                                                            }}
                                                                        >
                                                                            <div>
                                                                                <div>
                                                                                    <h5 className="eleven_font_class"
                                                                                        style={{
                                                                                            color: "#1F3977"
                                                                                        }}
                                                                                    >
                                                                                        Views
                                                                                    </h5>
                                                                                </div>

                                                                                <div
                                                                                    className="d-flex twenty_font_class"
                                                                                    style={{
                                                                                        flexWrap: "wrap",
                                                                                        marginTop: "5px",
                                                                                    }}
                                                                                >
                                                                                    {" "}

                                                                                    <div>{previewPollData.view_count}</div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="all_icon_imgs_div"
                                                                                style={{
                                                                                    background: "#FBE1FF",

                                                                                }}
                                                                            >
                                                                                <img className="all_icon_imgs"
                                                                                    src="dist/img/ComboChart.png"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>

                                            <div className="col-md-5">
                                                <section style={{ margin: "15px 0px" }}>
                                                    <div className="table-cards">
                                                        <div className="table-cards">
                                                            <div className="row">
                                                                <div
                                                                    className="col-md-12 p-0"
                                                                    style={{ height: "100%" }}
                                                                >
                                                                    <div
                                                                        className="small-box"
                                                                        style={{
                                                                            height: "70px",
                                                                            padding: "5px",
                                                                            borderRadius: "2.5PX",
                                                                            display: "flex",
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="inner"
                                                                            // onClick={UniDetails}
                                                                            style={{
                                                                                cursor: "pointer",
                                                                                display: "flex",
                                                                                width: "100%",
                                                                                justifyContent: "space-between",
                                                                            }}
                                                                        >
                                                                            <div>
                                                                                <div>
                                                                                    <h5 className="eleven_font_class"
                                                                                        style={{
                                                                                            color: "#1F3977"
                                                                                        }}
                                                                                    >
                                                                                        Likes
                                                                                    </h5>
                                                                                </div>

                                                                                <div
                                                                                    className="d-flex twenty_font_class"
                                                                                    style={{
                                                                                        flexWrap: "wrap",
                                                                                        marginTop: "5px",
                                                                                    }}
                                                                                >
                                                                                    <div>{previewPollData.likes_count}</div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="all_icon_imgs_div"
                                                                                style={{
                                                                                    background: "#BEF5C3"
                                                                                }}
                                                                            >
                                                                                <img className="all_icon_imgs"
                                                                                    src="dist/img/Thumbs_Up.png"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>







                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
